<template>
  <div class="top-list-container">
    <div class="top-list">
      <div class="row-between top-list-top">
        <p class="top-list-top-num">排行榜TOP{{ topRange }}</p>
        <p class="top-list-top-time">统计时间：{{timeArray}}</p>
      </div>
      <el-table
        v-if="lotteryListInfo.length"
        class="lottery-list-table"
        :data="lotteryListInfo"
      >
        <el-table-column
          prop="ranking"
          label="排名"
          width="60"
        >
          <template slot-scope="scope">
            <img
              style="width:20px;height: auto;margin-top:5px;"
              v-if="scope.row.ranking == 1 || scope.row.ranking == 2 || scope.row.ranking == 3"
              :src="scope.row.ranking == 1 ? require('../assets/icon/first-top.png') : scope.row.ranking == 2 ? require('../assets/icon/second-top.png') : require('../assets/icon/third-top.png')"
            />
            <p v-else>{{ scope.row.ranking }}</p>
          </template>

        </el-table-column>
        <el-table-column
          prop="uid"
          label="UID"
          width="60"
        >
        </el-table-column>
        <el-table-column
          prop="nickname"
          label="昵称"
        >
        </el-table-column>
        <el-table-column
          prop="inviteNum"
          label="邀请人数"
          width="80"
        >
        </el-table-column>
      </el-table>
      <div
        v-else
        class="col-center"
        style="margin-top: 30vh;"
      >
        <img
          style="width:6.5rem;height:auto;margin-bottom:1.375rem;"
          src="../assets/icon/no-list.png"
        />
        <p style="font-size: 16px;font-family: lantingheiweight;font-weight: 500;color: #666666;line-height: 22px;">暂无数据</p>
      </div>
    </div>

  </div>
</template> 
<script>
import Vue from 'vue';
import api from '../api/index-client'
import { Table,TableColumn } from 'element-ui';
Vue.use(Table)
Vue.use(TableColumn)
export default {
  data () {
    return {
      lotteryListInfo: [],
      topRange: '100',
      timeArray: '5月1日-5月5日',
    }
  },
  mounted () {
    // 获取活动排名
    this.getInviteList()
  },
  methods: {
    // 获取邀请信息
    getInviteList () {
      api
        .get('event/getUserEventInfo')
        .then(result => {
          if (result.data.success) {
            this.topRange = result.data.data.topRange
            this.timeArray = this.TimeFilter(result.data.data.startTime) + '~' + this.TimeFilter(result.data.data.endTime)
            this.getTopList()
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    TimeFilter (time) {
      let mouth = time.substring(5,7)
      let day = time.substring(8,10)
      return mouth + '.' + day
    },
    // 获取Top列表
    getTopList () {
      api
        .get('event/getTopList?id=' + this.$route.query.id + '&sort=ranking,ASC')
        .then(result => {
          if (result.data.success) {
            this.lotteryListInfo = result.data.data.content
            this.allInviteSize = result.data.data.totalElements
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    }
  },
}
</script>
<style lang="less" scoped>
.top-list-container {
  margin-top: 3.875rem;
  .top-list {
    margin-top: 8px;
    width: 94vw;
    margin-left: 3vw;
    min-height: calc(100vh - 70px);
    background: #262626;
    border-radius: 8px;
  }
  .top-list-top {
    width: 94%;
    margin-left: 3%;
    padding-top: 1.375rem;
    padding-bottom: 5px;
    .top-list-top-num {
      font-size: 14px;
      font-family: lantingheiweight;
      font-weight: bold;
      color: #ffffff;
      line-height: 19px;
    }
    .top-list-top-time {
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #a5a5a5;
      line-height: 20px;
    }
  }
  .lottery-list-table {
    margin-top: 13px;
    width: 94%;
    margin-left: 3%;
    background: #262626;
    border: 1px solid #3c3c3c;
    /deep/ tr,
    /deep/ th {
      background-color: #262626;
      border-bottom: 1px solid #3c3c3c;
    }
    /deep/th {
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #999999;
      line-height: 20px;
    }
    /deep/ td {
      border-bottom: 1px solid #3c3c3c;
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #999999;
      line-height: 20px;
    }
  }
  /deep/.lottery-list-table::before {
    display: none;
  }
}
</style>